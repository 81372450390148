<template>
	<v-flex fill-height>
		<v-layout justify-space-between pa-2 row>
			<v-flex v-t="'sa.support.actions.accounting-firm-own-user-delete.title'" headline shrink />
		</v-layout>
		<v-layout fill-height pa-4>
			<v-flex>
				<v-layout column justify-center>
					<v-flex my-3 shrink>
						<v-layout column>
							<v-flex v-t="'sa.support.actions.accounting-firm-own-user-delete.accounting-firm'" class="title" mb-3 />
							<v-autocomplete
								v-model="selectedAccountingFirmId"
								color="primary"
								:items="accountingFirms"
								:label="$tc('workspaces.workspace', 1)"
								:loading="loadingAccountingFirmsList"
							/>
						</v-layout>
					</v-flex>
					<v-flex my-3 shrink>
						<WButton :disabled="!selectedAccountingFirmId" @click="execute">{{ $t('actions.launch') }}</WButton>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'
import { mapState } from 'vuex'

export default {
	name: 'DeleteAccountingFirmOwnUser',
	mixins: [SuperAdminModuleGuard],
	data: function () {
		return {
			accountingFirms: new Array(),
			selectedAccountingFirmId: null,
			loadingAccountingFirmsList: false
		}
	},
	computed: {
		...mapState({
			user: state => state.user
		})
	},
	created: function () {
		this.getAccountingFirms()
	},
	methods: {
		getAccountingFirms: function () {
			this.loadingAccountingFirmsList = true
			this.service
				.getAccountingFirms()
				.then(accountingFirms => {
					return accountingFirms.map(accountingFirm => {
						return {
							text: accountingFirm.name,
							value: accountingFirm.id
						}
					})
				})
				.then(accountingFirms => {
					this.accountingFirms = accountingFirms
				})
				.finally(() => {
					this.loadingAccountingFirmsList = false
				})
		},
		execute: function () {
			return this.service
				.executeCommand({
					command: 'user:accountant:delete',
					params: {
						'accounting-firm-id': this.selectedAccountingFirmId,
						email: this.user.email
					}
				})
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.support.success'))
				})
				.catch(error => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error)
				})
		}
	}
}
</script>